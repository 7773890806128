<template>
  <div id="search-results-table">
    <div :style="{ height: '70vh', width: `100%` }">
      <el-auto-resizer>
        <template #default="{ height, width }">
          <el-table-v2
            :columns="columns"
            :data="searchResults"
            :height="height"
            :width="width"
            :header-height="rowHeight"
            :scrollbar-always-on="true"
            :estimated-row-height="rowHeight"
            :row-class="
              ({ rowIndex }) => (rowIndex % 2 === 0 ? 'even-row' : 'uneven-row')
            "
            header-class="header"
            :style="{ margin: 'auto' }"
            :fixed="false"
          />
        </template>
      </el-auto-resizer>
    </div>
  </div>
</template>

<script lang="tsx" setup>
import { ref, reactive, onMounted } from 'vue'
import { Column } from 'element-plus'
import SucheFormularResultateHeader from './SucheFormularResultateHeader.vue'

interface InputFieldsI {
  prop: string
  inputName: string
  label: string
  isInput: boolean
  isField: boolean
  value: string
  width: number
  order: string
  ordered: boolean
  showToggle: boolean
}

type Data = {
  [key: string]: any
}

const width0 = ref(1776)
let rowHeight = ref(23)
const width1 = ref(1776)

onMounted(() => {
  let width = document.getElementById('search-results-table')?.clientWidth!
  width0.value = width
  calcTableWidth()
  rowHeight.value = isWindowWiderThan()
})

function isWindowWiderThan(): number {
  return window.matchMedia(`(max-width: ${width1.value}px)`).matches ? 46 : 23
}

/**
 * Recalculate tabel-widths if columns are toggled such that total width remains constant. Is needed since the automatic
 * rendering of min-widths does not seem to work on el-table-v2, which is only a beta-version (even if :fixed="false" is set on el-table-v2).
 * TODO: Retry if el-table-v2 is released, use commented out properties in column (flexGrow etc.).
 */
function calcTableWidth() {
  let widthLoc = 0
  for (const col of props.inputFields) {
    if (col.showToggle) {
      widthLoc += col.width
    }
  }
  width1.value = widthLoc
  console.log(widthLoc)
  columns.forEach((_) => {
    _.width = ((width0.value - 5) / widthLoc) * _.minWidth! * 2
  })
}

const props = defineProps({
  inputFields: {
    type: Array as () => InputFieldsI[],
    default: () => [],
  },
  searchResults: {
    type: Array as () => Data[],
    default: () => [],
  },
})

const emit = defineEmits(['orderNew'])

const columns = reactive(
  props.inputFields.map(
    (_): Column<any> => ({
      key: _.prop,
      title: _.label,
      width: _.width,
      dataKey: _.prop,
      /**
       * Setting minWidth adn maxWidth seems to have no effect -- (mis-)-used to have reference to original width in calTableWidth
       */
      minWidth: _.width / 2,
      // flexGrow: _.prop === 'fileName' ? 3 : 1,
      flexGrow: 1,
      flexShrink: 1,
      cellRenderer: ({ rowData: row }) => {
        if (_.prop === 'fileName') {
          return (
            <span>
              {' '}
              {row.documentBitstreamUUID !== null ? (
                <a href={`../download.php?uuid=${row.documentBitstreamUUID}`}>
                  {row.fileName}
                </a>
              ) : (
                <a>{row.fileName}</a>
              )}
            </span>
          )
        } else if (_.prop === 'documentDate' || _.prop === 'documentDueDate') {
          return <span> {toDate(row[_.prop])} </span>
        } else if (
          _.prop === 'documentGrossAmount' ||
          _.prop === 'documentNetAmount'
        ) {
          return <span> {toAmountFormatted(row[_.prop])} </span>
        } else {
          return <span> {row[_.prop]} </span>
        }
      },
      headerCellRenderer: () => {
        return (
          <SucheFormularResultateHeader
            label={_.label}
            orderDirection={_.order}
            ordered={_.ordered}
            onOrderNew={(order) => {
              emit('orderNew', _.prop, order)
            }}
          />
        )
      },
    })
  )
)

function toDate(dateIn: string) {
  if (dateIn) {
    const date = new Date(dateIn)
    return date ? date.toLocaleDateString('de-CH') : dateIn
  } else {
    return dateIn
  }
}

function toAmountFormatted(amountIn: number) {
  const currencyFractionDigits = {
    style: 'decimal',
    currency: 'CHF',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  if (amountIn) {
    /**
     * Convert amountIn to number in order to use toLocaleString-method
     */
    let amountNumber
    if (typeof amountIn === 'string') {
      amountNumber = parseFloat(amountIn)
    } else {
      amountNumber = amountIn
    }
    const amountFormatted = amountNumber.toLocaleString(
      'de-CH',
      currencyFractionDigits
    )
    return amountFormatted
  } else {
    return amountIn
  }
}
</script>

<style>
/* #search-results-table .el-table-v2__row {
    position: static !important;
  } */
.uneven-row {
  font-size: 12px;
  white-space: no-wrap;
}
.even-row {
  font-size: 12px;
  white-space: no-wrap;
  background-color: #e6e6e6;
}
.header {
  height: 14px;
  bottom: 2px;
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
  color: #333333;
  border-top: 2px solid black;
  /* padding: 0 8px; */
}
</style>
